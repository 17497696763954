<template>
  <div class="learning-page user-page">
    <div class="content-wrapper">
      <h2>{{ campaignName }}</h2>
    </div>
    <b-card>
      <b-card-title>
        <span>Learning sections</span>
        <view-lang-select
          v-model="view_lang"
          :options="languages"
          class="float-right"
        />
      </b-card-title>
      <b-row class="mt-4">
        <b-col md="9" xl="6">
          <vue-nestable v-model="tree" @change="updateList">
            <template slot-scope="{ item }">
              <div class="nestable-whappy-item d-flex align-items-center">
                <div
                  class="
                    chevron-box
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <b-icon
                    icon="archive"
                    v-if="item.children && item.children.length > 0"
                  />
                  <b-icon icon="book" v-else />
                </div>
                <span class="ml-1">{{ item.name[view_lang] }} </span>
                <div
                  class="
                    tools-box
                    align-self-stretch
                    ml-auto
                    d-flex
                    align-items-center
                  "
                >
                  <vue-nestable-handle :item="item">
                    <b-icon icon="arrows-move"></b-icon>
                  </vue-nestable-handle>
                  <b-icon
                    icon="plus"
                    class="pointer"
                    v-b-toggle.sidebar-learning
                    @click="tmpSection.id_parent = item.id"
                  ></b-icon>
                  <b-icon
                    icon="gear-fill"
                    class="pointer"
                    v-b-toggle.sidebar-learning
                    @click="setTmpSection(item)"
                  >
                  </b-icon>
                  <b-icon
                    icon="trash-fill"
                    class="pointer"
                    @click="deleteCheck(item)"
                  ></b-icon>
                </div>
              </div>
            </template>
          </vue-nestable>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <b-button
            squared
            size="lg"
            class="button-dashed big-button"
            v-b-toggle.sidebar-learning
            >Add Section
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div class="content-wrapper" v-if="hasNextRoute()">
      <b-button squared variant="primary" size="lg" @click="toNextRoute()"
        >Save and continue</b-button
      >
    </div>
    <b-button
      v-if="isDebug"
      class="fixed-button py-2"
      v-b-toggle.sidebar-iframe
    >
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button>
    <b-sidebar
      @hidden="resetDataSidebarIframe"
      class="right-sidebar iframe-sidebar"
      id="sidebar-iframe"
      width="500px"
      right
      shadow
      lazy
    >
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select
          @change="getUrlDemo"
          :options="usersList"
          v-model="userSelected"
        >
        </b-select>
        <!-- user selectd: {{ userSelected }} || url demo: {{ url_demo }} -->
      </div>
      <div class="iframe-phone">
        <iframe
          class="iframe-phone-iframe"
          :src="url_demo"
          frameborder="0"
        ></iframe>
      </div>
    </b-sidebar>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-learning"
      right
      shadow
      lazy
      @hidden="resetData"
      no-header
    >
      <template #default="{ hide }">
        <div
          class="
            custom-header
            mx-3
            py-2
            position-absolute
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <b-icon-x class="text-dark sidebar-button x" @click="hide" />
          <span
            v-if="isChapterEditor"
            class="text-dark sidebar-button"
            @click="hideChapterEditor"
          >
            {{ tmpSection.name[name_lang] }}
            <b-icon-chevron-left class="mt-2" /> Back to section
          </span>
        </div>

        <div class="content-wrapper my-3 mx-1">
          <template v-if="!isChapterEditor">
            <h4 v-if="currentSection">Edit section</h4>
            <h4 v-else>New section</h4>
            <b-form-group label="Name">
              <b-form-input v-model="tmpSection.name[name_lang]" />
              <LangOptions v-model="name_lang" :options="languages" />
            </b-form-group>
            <div class="mt-4 mb-5">
              <b-button
                squared
                variant="primary"
                size="lg"
                class="big-button"
                @click="saveSection"
                >Save</b-button
              >
            </div>
            <hr />
            <template v-if="currentSection">
              <div class="d-flex align-items-center mt-5 mb-4">
                <h4 class="mb-0">Chapters</h4>
                <view-lang-select
                  v-model="chapter_view_lang"
                  :options="languages"
                  class="ml-auto"
                />
              </div>
              <chapter-list
                :idCategory="currentSection.id"
                :lang="chapter_view_lang"
                @chapter-edit="showChapterEditor"
              />
            </template>
          </template>
          <chapter-editor
            v-else
            :idCategory="currentSection.id"
            :id="currentChapter"
            :languages="languages"
            :default_lang="default_lang"
            @chapter-edit="showChapterEditor"
          />
        </div>
      </template>
    </b-sidebar>
    <b-modal v-model="showAlertModal" title="Attention!" ok-only
      >Cannot delete this item, remove all nested element before
      proceeding.</b-modal
    >
  </div>
</template>

<script>
import { VueNestable, VueNestableHandle } from "vue-nestable";
import AxiosService from "@/axiosServices/AxiosService";
import ChapterList from "../../components/Learning/ChapterList.vue";
import ChapterEditor from "../../components/Learning/ChapterEditor.vue";
import * as CampaignRoutes from "@/utils/CampaignRoutes.js";
export default {
  components: {
    VueNestable,
    VueNestableHandle,
    ChapterList,
    ChapterEditor,
  },
  data() {
    return {
      campaignName: "",

      isDebug: null,
      idCampaign: this.$route.params.campaignId,
      items: null,
      tree: [],
      currentSection: null,
      tmpSection: {},
      currentChapter: null,
      isChapterEditor: false,
      name_lang: null,
      view_lang: null,
      chapter_view_lang: null,
      showAlertModal: false,

      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,
    };
  },
  service: null,
  campaignService: null,
  created() {
    this.service = new AxiosService("LearningCategories");
    this.campaignService = new AxiosService("Campaign");

    this.resetData();
    this.view_lang = this.default_lang;
    this.name_lang = this.default_lang;
    this.chapter_view_lang = this.default_lang;

    //     this.service.readCustomEndpoint("Setting").then((res) => {
    //       if (res) {
    //         this.url_demo = res.url_demo;
    //       } else {
    //         console.log("ERROr calling Setting");
    //       }
    //     });
    this.isDebugFunction();
  },
  mounted() {
    if (!this.isNewCampaign) {
      this.getUsersByCampaign();
    }
    this.campaignService
      .readById(this.$route.params.campaignId)
      .then((data) => {
        this.campaignName = data.title[this.view_lang];
      });
    let params = [{ param: "idCampagna", value: this.idCampaign }];
    this.service.read(params).then((data) => {
      this.items = data;
      this.tree = this.buildTree(this.items);
    });
  },
  methods: {
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
    isDebugFunction() {
      this.isDebug = null;
      this.service
        .readCustomEndpoint(
          "CampaignManager/IsDebugEnabledOnCampaign/" +
            this.$route.params.campaignId
        )
        .then((res) => {
          this.isDebug = res;
          console.log("IS DEBUG? ", this.isDebug);
        });
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            let campaignNameForUrl = this.campaignName.split(" ").join("%20");
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.$route.params.campaignId +
              "/" +
              this.userSelected +
              "/learning";
            +"/" + campaignNameForUrl;
          } else {
            console.err("ERROr calling Setting");
          }
        });
      } else {
        this.url_demo = "";
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    resetDataSidebarIframe() {
      this.userSelected = null;
      this.url_demo = "";
    },
    resetData() {
      this.currentSection = null;
      this.tmpSection = {
        id: 0,
        id_parent: 0,
        order: 0,
        name: {},
      };
      this.name_lang = this.default_lang;
      this.chapter_view_lang = this.default_lang;
      this.currentChapter = null;
      this.isChapterEditor = false;
    },
    setTmpSection(item) {
      let index = this.items.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.currentSection = this.items[index];
        this.tmpSection = JSON.parse(JSON.stringify(this.currentSection));
      }
    },
    saveSection() {
      let item = { ...this.tmpSection };

      const validationObject = this.$validate("learning_section", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      let params = [{ param: "idCampagna", value: this.idCampaign }];

      if (item.id) {
        this.service
          .update(item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentSection).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentSection[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-learning");
              this.tree = this.buildTree(this.items);
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      } else {
        this.service
          .create(item, params)
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.items.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-learning");
              this.tree = this.buildTree(this.items);
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }
    },
    deleteCheck(item) {
      if (item.children && item.children.length) {
        this.showAlertModal = true;
      } else {
        this.deleteSection(item.id);
      }
    },
    deleteSection(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            );
            this.tree = this.buildTree(this.items);
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    showChapterEditor(id) {
      this.currentChapter = id;
      this.isChapterEditor = true;
    },
    hideChapterEditor() {
      this.isChapterEditor = false;
      this.currentChapter = null;
    },
    buildTree() {
      let list = JSON.parse(JSON.stringify(this.items));
      if (!list) return [];
      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_parent && node.id_parent !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_parent)) {
            list[map[node.id_parent]].children.push(node);
          }
          //else nodo orfano
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    flattenTree(children, parent) {
      return Array.prototype.concat.apply(
        children.map((x) => {
          x.id_parent = parent || 0;
          return x;
        }),
        children.map((x) => this.flattenTree(x.children || [], x.id))
      );
    },
    updateList(value) {
      this.items = this.flattenTree(this.tree);
      this.tree = this.buildTree(this.items); //non necessario, solo per verifica
      let item = this.items.find((x) => x.id === value.id);
      delete item.children;
      this.service
        .update(item)
        .then((response) => {
          if (response) {
            this.$successToast("Section level saved");
            this.updateOrder();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    updateOrder() {
      let newOrder = [];
      for (let i = 0; i < this.items.length; i++) {
        newOrder.push({ ID: this.items[i].id, Ordine: i });
      }
      this.service
        .updateOrder(newOrder, "Learning")
        .then(() => {
          this.$successToast("Order saved");
        })
        .catch(() => {
          this.$errorToast("Order not saved");
          return false;
        });
    },
    hasNextRoute() {
      return CampaignRoutes.hasNextRoute(this, "Learning");
    },
    toNextRoute() {
      CampaignRoutes.toNextRoute(this, "Learning");
    },
  },
  computed: {
    languages() {
      return this.$parent.companyLanguages;
    },
    default_lang() {
      return this.$parent.default_lang;
    },
  },
};
</script>
