<template>
  <div>
    <b-form-group label="Title" label-for="title">
      <b-form-input v-model="item.title[title_lang]" />
      <LangOptions v-model="title_lang" :options="languages" />
    </b-form-group>
    <b-form-group label="Description" label-for="description-en">
      <b-form-textarea v-model="item.description[description_lang]" rows="3" />
      <LangOptions v-model="description_lang" :options="languages" />
    </b-form-group>

    <files-upload-manager v-model="item.documents" />

    <div class="mt-5 mb-5">
      <b-button
        v-if="isLoading"
        squared
        variant="primary"
        size="lg"
        class="big-button"
        disabled
        ><b-spinner small type="grow"></b-spinner> Loading...</b-button
      >
      <b-button
        v-else
        squared
        variant="primary"
        size="lg"
        class="big-button"
        @click="saveItem"
        >Save Details</b-button
      >
    </div>
  </div>
</template>

<script>
import FilesUploadManager from "../FilesUploadManager.vue";
export default {
  components: {
    FilesUploadManager,
  },
  props: ["details", "isLoading", "languages", "default_lang"],
  data() {
    return {
      title_lang: null,
      description_lang: null,
      item: {},
    };
  },
  created() {
    this.title_lang = this.default_lang;
    this.description_lang = this.default_lang;
    this.item = this.details;
  },
  methods: {
    saveItem() {
      this.$emit("save-details", this.item);
    },
  },
  watch: {
    details: {
      handler(newVal) {
        this.item = newVal;
      },
      deep: true,
    },
  },
};
</script>
